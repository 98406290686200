import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["contactUsObject", "variation", "multiLocationMenuContainers", "locationName", "addressObject", "contactAddressObject", "copyRightObject", "hoursOfOperationObject", "logoImage", "mapImage", "primaryLinksObject", "utilityLinksObject", "socialIconsData", "dataAnalyticsLogo", "dataAnalyticsAddress", "dataAnalyticsPrimaryLinks", "dataAnalyticsSecondaryLinks", "dataAnalyticsSocialLinks", "miniFooterCTA", "mapUrlOverride", "googleMyBusinessCID", "globalFooterProps", "themeName"];
var __jsx = React.createElement;
/**
 * @author Harmeet
 */import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import styles from './Footer.style';
import Image from '../../atoms/Image';
import ContactCard from './ContactCard';
import SocialIcons from '../SocialIcons';
import HoursOfOperation from '../HoursOfOperation';
import List from '../List';
import ListItem from '../List/ListItem';
import { getYear } from '../../../utils/utilityFunction';
import Anchor from '../../atoms/Anchor';
import MapAnchor from '../../atoms/MapAnchor';
import constants from '../../../constants/globalConstants';
import { FooterCopyright̽����Ƶ } from '../../../constants/labels/en';
import MultiLocationGrid from './Multi-locations/MultiLocationGrid';
import { srcSetSettingImgMapVariationA, srcSetSettingImgMapVariationB, getSrcSetSettingImg } from './FooterConfig';
import { Context } from '../../../global-state-context';
// import  { useAllYextMultiData }  from "../../../hooks/useAllYextMultiData"

var FooterSection = styled.section.withConfig({
  componentId: "sc-1nhohxj-0"
})(["", ";"], styles);
var Footer = function Footer(_ref) {
  var contactUsObject = _ref.contactUsObject,
    variation = _ref.variation,
    multiLocationMenuContainers = _ref.multiLocationMenuContainers,
    locationName = _ref.locationName,
    addressObject = _ref.addressObject,
    contactAddressObject = _ref.contactAddressObject,
    copyRightObject = _ref.copyRightObject,
    hoursOfOperationObject = _ref.hoursOfOperationObject,
    logoImage = _ref.logoImage,
    mapImage = _ref.mapImage,
    primaryLinksObject = _ref.primaryLinksObject,
    utilityLinksObject = _ref.utilityLinksObject,
    socialIconsData = _ref.socialIconsData,
    dataAnalyticsLogo = _ref.dataAnalyticsLogo,
    dataAnalyticsAddress = _ref.dataAnalyticsAddress,
    dataAnalyticsPrimaryLinks = _ref.dataAnalyticsPrimaryLinks,
    dataAnalyticsSecondaryLinks = _ref.dataAnalyticsSecondaryLinks,
    dataAnalyticsSocialLinks = _ref.dataAnalyticsSocialLinks,
    miniFooterCTA = _ref.miniFooterCTA,
    mapUrlOverride = _ref.mapUrlOverride,
    googleMyBusinessCID = _ref.googleMyBusinessCID,
    globalFooterProps = _ref.globalFooterProps,
    themeName = _ref.themeName,
    others = _objectWithoutProperties(_ref, _excluded);
  var _useContext = useContext(Context),
    websiteName = _useContext.websiteName;
  var logoSrcSetSettingImg = getSrcSetSettingImg(variation, themeName === 'Equine');
  var _useState = useState(getYear()),
    year = _useState[0];
  var UtilityLinks = function UtilityLinks() {
    return __jsx("div", {
      className: "nva-footer-old__utility-links col-md-9 bottomLinks"
    }, __jsx("div", {
      className: "nva-footer-old__utility-links-row row linksRow"
    }, __jsx("div", {
      className: "nva-footer-old__utility-links-copyright copyright copyright-desktop-display col-xs-12 col-md-3 col-lg-4"
    }, __jsx("div", {
      className: "nva-footer-old__utility-links-copyright-text heading_five heading-5 no-bar"
    }, FooterCopyright̽����Ƶ.copyrightSymbol, " ", FooterCopyright̽����Ƶ.copyright, ' ', year)), utilityLinksObject && __jsx("div", {
      className: "utilityLinks col-xs-12 col-md-9 col-lg-8"
    }, __jsx(List, {
      ordered: false,
      className: "nva-footer-old__utility-links-list"
    }, utilityLinksObject.map(function (listItem, index) {
      return __jsx(ListItem, {
        key: index
      }, __jsx("div", {
        className: "nva-footer-old__utility-links-list-item heading_five heading-5 no-bar"
      }, __jsx(Anchor, {
        className: "nva-footer-old__utility-links-list-item-anchor",
        title: listItem.label,
        "aria-label": listItem.label,
        to: listItem.url,
        hyperlinkType: listItem.hyperlinkType,
        "data-analytics-type": dataAnalyticsSecondaryLinks && dataAnalyticsSecondaryLinks.type ? dataAnalyticsSecondaryLinks.type : '',
        "data-analytics-variation": dataAnalyticsSecondaryLinks && dataAnalyticsSecondaryLinks.variation ? dataAnalyticsSecondaryLinks.variation : '',
        "data-analytics-value": listItem.dataAnalyticsValue ? listItem.dataAnalyticsValue : dataAnalyticsSecondaryLinks && dataAnalyticsSecondaryLinks.value ? dataAnalyticsSecondaryLinks.value : '',
        styleType: "language-link",
        ctaBehavior: listItem.behavior
      }, listItem.label)));
    })))));
  };
  var SocialLinks = function SocialLinks() {
    return __jsx(React.Fragment, null, socialIconsData && __jsx("div", {
      className: "nva-footer-old__social-icons copyright col-xs-12 col-md-3"
    }, __jsx(SocialIcons, {
      locationName: locationName,
      dataAnalyticsSocialLinks: dataAnalyticsSocialLinks,
      socialIconsData: socialIconsData
    })), __jsx("div", {
      className: "nva-footer-old__social-icons-copyright copyright copyright-mobile-display col-xs-12 col-md-3 col-lg-4"
    }, __jsx("div", {
      className: "nva-footer-old__social-icons-copyright-text heading_five heading-5 no-bar"
    }, FooterCopyright̽����Ƶ.copyrightSymbol, " ", FooterCopyright̽����Ƶ.copyright, " ", year)));
  };
  var MiniFooter = function MiniFooter() {
    var _miniFooterCTA$sys;
    return __jsx(FooterSection, {
      globalFooterProps: globalFooterProps,
      className: "nva-footer-old__mini-footer footer molecule Mini-Footer"
    }, __jsx("div", {
      className: "nva-footer-old__mini-footer-row row around-md middle-md"
    }, logoImage && __jsx("div", {
      className: "nva-footer-old__mini-footer-business-logo businessLogo col-sm-1 col-xs-7",
      itemScope: true,
      itemType: "http://schema.org/ImageObject"
    }, __jsx(Anchor, {
      title: "Logo",
      to: "/",
      className: "nva-footer-old__mini-footer-logo-wrapper miniFooterLogoWrapper footer-logo",
      "data-analytics-type": dataAnalyticsLogo && dataAnalyticsLogo.type ? dataAnalyticsLogo.type : '',
      "data-analytics-variation": dataAnalyticsLogo && dataAnalyticsLogo.variation ? dataAnalyticsLogo.variation : '',
      "data-analytics-value": dataAnalyticsLogo && dataAnalyticsLogo.value ? dataAnalyticsLogo.value : ''
    }, __jsx(Image, {
      className: "nva-footer-old__mini-footer-image",
      srcSetSettingImg: logoSrcSetSettingImg,
      "data-analytics-type": dataAnalyticsLogo && dataAnalyticsLogo.type ? dataAnalyticsLogo.type : '',
      "data-analytics-variation": dataAnalyticsLogo && dataAnalyticsLogo.variation ? dataAnalyticsLogo.variation : '',
      "data-analytics-value": dataAnalyticsLogo && dataAnalyticsLogo.value ? dataAnalyticsLogo.value : '',
      "data-object-fit": "contain",
      "data-object-position": "30%",
      alt: websiteName ? "".concat(websiteName, " Logo") : 'Logo',
      title: websiteName ? "".concat(websiteName, " Logo") : 'Logo',
      contentType: logoImage.contentType,
      src: logoImage.src,
      imageOptimisationType: 'logo'
    }), __jsx("link", {
      href: logoImage.src,
      itemProp: "logo"
    }))), __jsx("div", {
      className: "nva-footer-old__mini-footer-copyright copyright"
    }, __jsx("div", {
      className: "nva-footer-old__mini-footer-copyright-text heading_five heading-5 no-bar"
    }, FooterCopyright̽����Ƶ.copyrightSymbol, " ", FooterCopyright̽����Ƶ.copyright, ' ', year)), utilityLinksObject && __jsx("div", {
      className: "nva-footer-old__mini-footer-utility-links utilityLinks"
    }, __jsx(List, {
      ordered: false,
      className: "nva-footer-old__mini-footer-utility-links-list"
    }, utilityLinksObject.map(function (listItem, index) {
      return __jsx(ListItem, {
        key: index,
        className: "nva-footer-old__mini-footer-utility-links-list-item"
      }, __jsx("div", {
        className: "heading_five heading-5 no-bar"
      }, __jsx(Anchor, {
        className: "nva-footer-old__mini-footer-utility-links-list-item-anchor",
        title: listItem.label,
        "aria-label": listItem.label,
        to: listItem.url,
        hyperlinkType: listItem.hyperlinkType,
        "data-analytics-type": dataAnalyticsSecondaryLinks && dataAnalyticsSecondaryLinks.type ? dataAnalyticsSecondaryLinks.type : '',
        "data-analytics-variation": dataAnalyticsSecondaryLinks && dataAnalyticsSecondaryLinks.variation ? dataAnalyticsSecondaryLinks.variation : '',
        "data-analytics-value": listItem.dataAnalyticsValue ? listItem.dataAnalyticsValue : dataAnalyticsSecondaryLinks && dataAnalyticsSecondaryLinks.value ? dataAnalyticsSecondaryLinks.value : '',
        styleType: "language-link",
        ctaBehavior: listItem.behavior
      }, listItem.label)));
    }))), socialIconsData &&
    // TODO - do not show social icons, if multi location is present
    __jsx("div", {
      className: "nva-footer-old__mini-footer-social-icons socialIcons"
    }, __jsx(SocialIcons, {
      locationName: locationName,
      dataAnalyticsSocialLinks: dataAnalyticsSocialLinks,
      socialIconsData: socialIconsData
    })), miniFooterCTA && __jsx("div", {
      className: "nva-footer-old__mini-footer-cta miniFooterCTA"
    }, __jsx(Anchor, {
      "data-entry-id": (_miniFooterCTA$sys = miniFooterCTA.sys) === null || _miniFooterCTA$sys === void 0 ? void 0 : _miniFooterCTA$sys.id,
      title: miniFooterCTA.label,
      to: miniFooterCTA.url,
      hyperlinkType: miniFooterCTA.hyperlinkType,
      className: "nva-footer-old__mini-footer-cta-anchor",
      styleType: 'primary-anchor',
      ctaBehavior: miniFooterCTA.behavior,
      "aria-label": miniFooterCTA.ariaLabel
      // data-analytics-type={
      //   dataAnalytics && dataAnalytics.type ? dataAnalytics.type : ''
      // }
      ,
      "data-analytics-value": miniFooterCTA.dataAnalyticsValue ? miniFooterCTA.dataAnalyticsValue : ''
      // data-analytics-variation={
      //   dataAnalytics && dataAnalytics.variation
      //     ? dataAnalytics.variation
      //     : ''
      // }
    }, miniFooterCTA.label))));
  };
  var PrimaryLinksFooter = function PrimaryLinksFooter() {
    var _miniFooterCTA$sys2;
    return __jsx("div", {
      className: "nva-footer-old__primary-links row around-md middle-md primaryLinks"
    }, logoImage && __jsx("div", {
      className: "nva-footer-old__primary-links-business-logo businessLogo col-sm-1 col-xs-7",
      itemScope: true,
      itemType: "http://schema.org/ImageObject"
    }, __jsx(Anchor, {
      title: "Logo",
      to: "/",
      className: "nva-footer-old__primary-links-business-logo-wrapper miniFooterLogoWrapper footer-logo",
      "data-analytics-type": dataAnalyticsLogo && dataAnalyticsLogo.type ? dataAnalyticsLogo.type : '',
      "data-analytics-variation": dataAnalyticsLogo && dataAnalyticsLogo.variation ? dataAnalyticsLogo.variation : '',
      "data-analytics-value": dataAnalyticsLogo && dataAnalyticsLogo.value ? dataAnalyticsLogo.value : ''
    }, __jsx(Image, {
      className: "nva-footer-old__primary-links-business-logo-image",
      srcSetSettingImg: logoSrcSetSettingImg,
      imageOptimisationType: 'logo',
      "data-analytics-type": dataAnalyticsLogo && dataAnalyticsLogo.type ? dataAnalyticsLogo.type : '',
      "data-analytics-variation": dataAnalyticsLogo && dataAnalyticsLogo.variation ? dataAnalyticsLogo.variation : '',
      "data-analytics-value": dataAnalyticsLogo && dataAnalyticsLogo.value ? dataAnalyticsLogo.value : '',
      "data-object-fit": "contain",
      "data-object-position": "30%",
      alt: websiteName ? "".concat(websiteName, " Logo") : 'Logo',
      title: websiteName ? "".concat(websiteName, " Logo") : 'Logo',
      contentType: logoImage.contentType,
      src: logoImage.src
    }), __jsx("link", {
      href: logoImage.src
    }))), primaryLinksObject && primaryLinksObject.length > 0 && __jsx(List, {
      ordered: false,
      className: "nva-footer-old__primary-links-list primaryLinksList"
    }, primaryLinksObject.map(function (listItem, index) {
      return __jsx(ListItem, {
        key: index,
        className: "nva-footer-old__primary-links-list-item"
      }, __jsx("div", {
        className: "heading_four primary-link-header no-bar"
      }, __jsx(Anchor, {
        title: listItem.label,
        "aria-label": listItem.ariaLabel,
        to: listItem.url,
        hyperlinkType: listItem.hyperlinkType,
        "data-analytics-type": dataAnalyticsPrimaryLinks && dataAnalyticsPrimaryLinks.type ? dataAnalyticsPrimaryLinks.type : '',
        "data-analytics-variation": dataAnalyticsPrimaryLinks && dataAnalyticsPrimaryLinks.variation ? dataAnalyticsPrimaryLinks.variation : '',
        "data-analytics-value": listItem.dataAnalyticsValue ? listItem.dataAnalyticsValue : dataAnalyticsPrimaryLinks && dataAnalyticsPrimaryLinks.value ? dataAnalyticsPrimaryLinks.value : '',
        className: "nva-footer-old__primary-links-list-item-anchor primary-link-anchor",
        ctaBehavior: listItem.behavior
      }, listItem.label)));
    })), miniFooterCTA && __jsx("div", {
      className: "nva-footer-old__primary-links-cta miniFooterCTA"
    }, __jsx(Anchor, {
      "data-entry-id": (_miniFooterCTA$sys2 = miniFooterCTA.sys) === null || _miniFooterCTA$sys2 === void 0 ? void 0 : _miniFooterCTA$sys2.id,
      title: miniFooterCTA.label,
      "aria-label": miniFooterCTA.ariaLabel,
      to: miniFooterCTA.url,
      hyperlinkType: miniFooterCTA.hyperlinkType,
      className: "nva-footer-old__primary-links-cta-anchor",
      styleType: 'primary-anchor',
      ctaBehavior: miniFooterCTA.behavior
      // data-analytics-type={
      //   dataAnalytics && dataAnalytics.type ? dataAnalytics.type : ''
      // }
      ,
      "data-analytics-value": miniFooterCTA.dataAnalyticsValue ? miniFooterCTA.dataAnalyticsValue : ''
      // data-analytics-variation={
      //   dataAnalytics && dataAnalytics.variation
      //     ? dataAnalytics.variation
      //     : ''
      // }
    }, miniFooterCTA.label)), __jsx(UtilityLinks, null), __jsx(SocialLinks, null));
  };
  var MultilocationFooter = function MultilocationFooter() {
    return __jsx(FooterSection, {
      globalFooterProps: globalFooterProps,
      className: "nva-footer-old--multi-location footer molecule ".concat(variation && "nva-footer-old--".concat(variation.toLowerCase(), " ").concat(variation.toLowerCase()))
    }, __jsx(MultiLocationGrid, {
      multiLocationMenuContainers: multiLocationMenuContainers,
      globalFooterProps: globalFooterProps,
      className: "nva-footer-old__multi-location-grid"
    }), __jsx("div", {
      className: "nva-footer-old__multi-location-line-separator line-separator col-xs-12"
    }), __jsx(PrimaryLinksFooter, {
      className: "nva-footer-old__multi-location-primary-links"
    }));
  };
  if (variation === 'Multi-location-footer') return __jsx(MultilocationFooter, null);else if (variation === 'Mini-Footer') {
    return __jsx(MiniFooter, null);
  } else if (variation === constants.variations.variationB) {
    var _miniFooterCTA$sys3;
    return __jsx(FooterSection, {
      globalFooterProps: globalFooterProps,
      className: "nva-footer-old footer molecule row ".concat(variation && variation.toLowerCase())
    }, __jsx("div", {
      className: "col-xs-12 col-md-3"
    }, __jsx("div", {
      className: "nva-footer-old__logo-social-container row logo-social-container"
    }, logoImage && __jsx("div", {
      className: "nva-footer-old__business-logo businessLogo col-xs-12",
      itemScope: true,
      itemType: "http://schema.org/ImageObject"
    }, __jsx(Anchor, {
      title: "Logo",
      to: "/",
      className: "nva-footer-old__business-logo-anchor footer-logo",
      "data-analytics-type": dataAnalyticsLogo && dataAnalyticsLogo.type ? dataAnalyticsLogo.type : '',
      "data-analytics-variation": dataAnalyticsLogo && dataAnalyticsLogo.variation ? dataAnalyticsLogo.variation : '',
      "data-analytics-value": dataAnalyticsLogo && dataAnalyticsLogo.value ? dataAnalyticsLogo.value : ''
    }, __jsx(Image, {
      className: "nva-footer-old__business-logo-image",
      "data-analytics-type": dataAnalyticsLogo && dataAnalyticsLogo.type ? dataAnalyticsLogo.type : '',
      "data-analytics-variation": dataAnalyticsLogo && dataAnalyticsLogo.variation ? dataAnalyticsLogo.variation : '',
      "data-analytics-value": dataAnalyticsLogo && dataAnalyticsLogo.value ? dataAnalyticsLogo.value : '',
      "data-object-fit": "contain",
      "data-object-position": "30%",
      alt: websiteName ? "".concat(websiteName, " Logo") : 'Logo',
      title: websiteName ? "".concat(websiteName, " Logo") : 'Logo',
      contentType: logoImage.contentType,
      src: logoImage.src,
      imageOptimisationType: 'logo',
      srcSetSettingImg: logoSrcSetSettingImg
    }), __jsx("link", {
      href: logoImage.src
    }))), socialIconsData && __jsx("div", {
      className: "nva-footer-old__social-icons copyright col-xs-12"
    }, __jsx(SocialIcons, {
      locationName: locationName,
      dataAnalyticsSocialLinks: dataAnalyticsSocialLinks,
      socialIconsData: socialIconsData
    })))), primaryLinksObject && __jsx("div", {
      className: "nva-footer-old__primary-links primaryLinks col-xs-12 col-md-2"
    }, miniFooterCTA && __jsx("div", {
      className: "nva-footer-old__mini-footer-cta miniFooterCTA"
    }, __jsx(Anchor, {
      "data-entry-id": (_miniFooterCTA$sys3 = miniFooterCTA.sys) === null || _miniFooterCTA$sys3 === void 0 ? void 0 : _miniFooterCTA$sys3.id,
      title: miniFooterCTA.label,
      "aria-label": miniFooterCTA.ariaLabel,
      to: miniFooterCTA.url,
      hyperlinkType: miniFooterCTA.hyperlinkType,
      className: "nva-footer-old__mini-footer-cta-anchor",
      styleType: 'primary-anchor',
      ctaBehavior: miniFooterCTA.behavior
      // data-analytics-type={
      //   dataAnalytics && dataAnalytics.type ? dataAnalytics.type : ''
      // }
      ,
      "data-analytics-value": miniFooterCTA.dataAnalyticsValue ? miniFooterCTA.dataAnalyticsValue : ''
      // data-analytics-variation={
      //   dataAnalytics && dataAnalytics.variation
      //     ? dataAnalytics.variation
      //     : ''
      // }
    }, miniFooterCTA.label)), __jsx(List, {
      ordered: false,
      className: "nva-footer-old__primary-links-list"
    }, primaryLinksObject.map(function (listItem, index) {
      return __jsx(ListItem, {
        key: index,
        className: "nva-footer-old__primary-links-list-item"
      }, __jsx("div", {
        className: "heading_four primary-link-header no-bar"
      }, __jsx(Anchor, {
        title: listItem.label,
        "aria-label": listItem.ariaLabel,
        to: listItem.url,
        hyperlinkType: listItem.hyperlinkType,
        "data-analytics-type": dataAnalyticsPrimaryLinks && dataAnalyticsPrimaryLinks.type ? dataAnalyticsPrimaryLinks.type : '',
        "data-analytics-variation": dataAnalyticsPrimaryLinks && dataAnalyticsPrimaryLinks.variation ? dataAnalyticsPrimaryLinks.variation : '',
        "data-analytics-value": listItem.dataAnalyticsValue ? listItem.dataAnalyticsValue : dataAnalyticsPrimaryLinks && dataAnalyticsPrimaryLinks.value ? dataAnalyticsPrimaryLinks.value : '',
        className: "nva-footer-old__primary-links-list-item-anchor primary-link-anchor",
        ctaBehavior: listItem.behavior
      }, listItem.label)));
    }))), __jsx("div", {
      className: "nva-footer-old__map-address-container col-xs-12 col-md-4 map-address-container"
    }, __jsx("div", {
      className: "nva-footer-old__map-address-container-row row"
    }, contactUsObject && __jsx("div", {
      className: "nva-footer-old__map-address-container-col addressCard col-xs-12"
    }, __jsx(ContactCard, {
      className: "nva-footer-old__map-address-container-contact-card",
      googleMyBusinessCID: googleMyBusinessCID,
      contactUsObject: contactUsObject,
      dataAnalyticsAddress: dataAnalyticsAddress
    })), mapImage && mapImage.src && __jsx("div", {
      className: "nva-footer-old__map-address-container-location-map locationMap col-xs-12"
    }, __jsx(MapAnchor, _extends({}, addressObject, {
      mapUrlOverride: mapUrlOverride && mapUrlOverride,
      className: "nva-footer-old__map-address-container-location-map-anchor"
    }), __jsx(Image, {
      className: "nva-footer-old__map-address-container-location-map-image",
      alt: mapImage.alt || 'Logo',
      contentType: mapImage.contentType,
      title: mapImage.title || 'Logo',
      src: mapImage.src,
      srcSetSettingImg: srcSetSettingImgMapVariationB,
      imageOptimisationType: 'logo'
    }))))), hoursOfOperationObject && __jsx("div", {
      className: "nva-footer-old__hours-of-operation hoursOfOperation col-xs-12 col-md-3"
    }, __jsx(HoursOfOperation, hoursOfOperationObject)), copyRightObject && copyRightObject.copyRightText && __jsx("div", {
      className: "nva-footer-old__copyright copyright name col-xs-12 col-md-5 col-lg-3"
    }, __jsx("div", {
      className: "nva-footer-old__copyright-text heading_five heading-5 no-bar"
    }, FooterCopyright̽����Ƶ.copyrightSymbol, FooterCopyright̽����Ƶ.copyright, " ", year)), utilityLinksObject && __jsx("div", {
      className: "nva-footer-old__utility-links utilityLinks col-xs-12 col-md-7 col-lg-9"
    }, __jsx(List, {
      ordered: false,
      className: "nva-footer-old__utility-links-list row"
    }, utilityLinksObject.map(function (listItem, index) {
      return __jsx(ListItem, {
        key: index,
        className: "nva-footer-old__utility-links-list-item"
      }, __jsx("div", {
        className: "heading_five heading-5 no-bar"
      }, __jsx(Anchor, {
        className: "nva-footer-old__utility-links-list-item-anchor",
        title: listItem.label,
        "aria-label": listItem.ariaLabel,
        to: listItem.url,
        hyperlinkType: listItem.hyperlinkType,
        "data-analytics-type": dataAnalyticsSecondaryLinks && dataAnalyticsSecondaryLinks.type ? dataAnalyticsSecondaryLinks.type : '',
        "data-analytics-variation": dataAnalyticsSecondaryLinks && dataAnalyticsSecondaryLinks.variation ? dataAnalyticsSecondaryLinks.variation : '',
        "data-analytics-value": listItem.dataAnalyticsValue ? listItem.dataAnalyticsValue : dataAnalyticsSecondaryLinks && dataAnalyticsSecondaryLinks.value ? dataAnalyticsSecondaryLinks.value : '',
        styleType: "language-link",
        ctaBehavior: listItem.behavior
      }, listItem.label)));
    }))));
  } else {
    var _miniFooterCTA$sys4;
    return __jsx(FooterSection, {
      globalFooterProps: globalFooterProps,
      className: "nva-footer-old footer molecule row ".concat(variation && "nva-footer-old--".concat(variation.toLowerCase(), " ").concat(variation.toLowerCase()))
    }, logoImage && __jsx("div", {
      className: "nva-footer-old__business-logo businessLogo col-xs-12 col-md-2",
      itemScope: true,
      itemType: "http://schema.org/ImageObject"
    }, __jsx(Anchor, {
      title: "Logo",
      to: "/",
      className: "nva-footer-old__business-logo-anchor footer-logo",
      "data-analytics-type": dataAnalyticsLogo && dataAnalyticsLogo.type ? dataAnalyticsLogo.type : '',
      "data-analytics-variation": dataAnalyticsLogo && dataAnalyticsLogo.variation ? dataAnalyticsLogo.variation : '',
      "data-analytics-value": dataAnalyticsLogo && dataAnalyticsLogo.value ? dataAnalyticsLogo.value : ''
    }, __jsx(Image, {
      className: "nva-footer-old__business-logo-image",
      "data-analytics-type": dataAnalyticsLogo && dataAnalyticsLogo.type ? dataAnalyticsLogo.type : '',
      "data-analytics-variation": dataAnalyticsLogo && dataAnalyticsLogo.variation ? dataAnalyticsLogo.variation : '',
      "data-analytics-value": dataAnalyticsLogo && dataAnalyticsLogo.value ? dataAnalyticsLogo.value : '',
      alt: websiteName ? "".concat(websiteName, " Logo") : 'Logo',
      title: websiteName ? "".concat(websiteName, " Logo") : 'Logo',
      contentType: logoImage.contentType,
      src: logoImage.src,
      imageOptimisationType: 'logo',
      srcSetSettingImg: logoSrcSetSettingImg
    }), __jsx("link", {
      href: logoImage.src
    }))), mapImage && mapImage.src && __jsx("div", {
      className: "nva-footer-old__location-map locationMap col-xs-12 col-md-2"
    }, __jsx(MapAnchor, _extends({}, addressObject, {
      mapUrlOverride: mapUrlOverride && mapUrlOverride,
      className: "nva-footer-old__location-map-anchor"
    }), __jsx(Image, {
      className: "nva-footer-old__location-map-image",
      alt: mapImage.alt || 'Logo',
      title: mapImage.title || 'Logo',
      src: mapImage.src,
      contentType: mapImage.contentType,
      srcSetSettingImg: srcSetSettingImgMapVariationA,
      imageOptimisationType: (mapImage === null || mapImage === void 0 ? void 0 : mapImage.contentType) && mapImage.contentType.includes('png') && 'logo'
    }))), contactUsObject && __jsx("div", {
      className: "nva-footer-old__address-card addressCard col-xs-12 col-md-3"
    }, __jsx(ContactCard, {
      googleMyBusinessCID: googleMyBusinessCID,
      contactUsObject: contactUsObject,
      addressObject: addressObject,
      dataAnalyticsAddress: dataAnalyticsAddress
    })), hoursOfOperationObject && __jsx("div", {
      className: "nva-footer-old__hours-of-operation hoursOfOperation col-xs-12 col-md-3"
    }, __jsx(HoursOfOperation, hoursOfOperationObject)), primaryLinksObject && __jsx("div", {
      className: "nva-footer-old__primary-links primaryLinks col-xs-12 col-md-2"
    }, miniFooterCTA && __jsx("div", {
      className: "nva-footer-old__primary-links-mini-footer-cta miniFooterCTA"
    }, __jsx(Anchor, {
      "data-entry-id": (_miniFooterCTA$sys4 = miniFooterCTA.sys) === null || _miniFooterCTA$sys4 === void 0 ? void 0 : _miniFooterCTA$sys4.id,
      title: miniFooterCTA.label,
      "aria-label": miniFooterCTA.ariaLabel,
      to: miniFooterCTA.url,
      hyperlinkType: miniFooterCTA.hyperlinkType,
      className: "nva-footer-old__primary-links-mini-footer-cta-anchor",
      styleType: 'primary-anchor',
      ctaBehavior: miniFooterCTA.behavior,
      "data-analytics-type": "footer-primary-CTA",
      "data-analytics-value": miniFooterCTA.dataAnalyticsValue ? miniFooterCTA.dataAnalyticsValue : ''
      // data-analytics-variation={
      //   dataAnalytics && dataAnalytics.variation
      //     ? dataAnalytics.variation
      //     : ''
      // }
    }, miniFooterCTA.label)), __jsx(List, {
      ordered: false,
      className: "nva-footer-old__primary-links-list"
    }, primaryLinksObject.map(function (listItem, index) {
      return __jsx(ListItem, {
        key: index,
        className: "nva-footer-old__primary-links-list-item"
      }, __jsx("div", {
        className: "heading_four primary-link-header no-bar"
      }, __jsx(Anchor, {
        title: listItem.label,
        "aria-label": listItem.ariaLabel,
        to: listItem.url,
        hyperlinkType: listItem.hyperlinkType,
        "data-analytics-type": dataAnalyticsPrimaryLinks && dataAnalyticsPrimaryLinks.type ? dataAnalyticsPrimaryLinks.type : '',
        "data-analytics-variation": dataAnalyticsPrimaryLinks && dataAnalyticsPrimaryLinks.variation ? dataAnalyticsPrimaryLinks.variation : '',
        "data-analytics-value": listItem.dataAnalyticsValue ? listItem.dataAnalyticsValue : dataAnalyticsPrimaryLinks && dataAnalyticsPrimaryLinks.value ? dataAnalyticsPrimaryLinks.value : '',
        className: "nva-footer-old__primary-links-list-item-anchor primary-link-anchor",
        ctaBehavior: listItem.behavior
      }, listItem.label)));
    }))), __jsx("div", {
      className: "nva-footer-old__line-separator line-separator col-xs-12"
    }), __jsx(UtilityLinks, null), __jsx(SocialLinks, null));
  }
};
Footer.defaultProps = {};
export default styled(Footer).withConfig({
  componentId: "sc-1nhohxj-1"
})(["", ";"], styles);
export { Footer as CarouselVanilla };